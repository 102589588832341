<template>
  <div class="init background-wallpaper-desktop">
    <b-col cols="12">
      <img
        class="init-logo"
        src="./../../assets/logo/logo-wide.png"
        alt="Logo"
      />
    </b-col>
    <b-col
      sm="10"
      offset-sm="1"
      md="8"
      offset-md="2"
      lg="6"
      offset-lg="3"
      xl="4"
      offset-xl="4"
      class="init-text"
    >
      <div v-if="!claim.is_routed">
        <p class="typography-h2">
          <n-html :text="$t('claim-processing.subtitle')" />
        </p>
        <p class="typography-p-regular">
          <n-html :text="$t('claim-processing.subtitle')" />
        </p>
        <div class="init-progress">
          <div class="init-progress-indeterminate"></div>
        </div>
      </div>
      <b-card
        v-else
        :title="$t('claim-processing.result-title')"
        img-alt="Image"
        img-top
        tag="article"
        class="mb-2"
      >
        <b-card-text>
          <n-html :text="$t('claim-processing.result-text')" />
        </b-card-text>

        <b-card-body>
          <n-html :text="claim.status" />

          <!-- als claim is geaccepteerd -->

          <div v-if="rejected">
            <n-html :text="this.claim.customer_notification" />
          </div>

          <div v-else class="init-progress">
            <p>loading</p>
            <div class="init-progress-indeterminate"></div>
          </div>
        </b-card-body>

        <b-button href="/" variant="primary">
          <n-html :text="$t('claim-processing.return-button')" />
        </b-button>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "pages.claims.loading",
  computed: {
    claim() {
      return this.$store.getters["claims/getClaim"];
    },
    store() {
      return this.$store.getters["claims/getStore"];
    }
  },
  data() {
    return {
      checker: null,
      loadingNextStep: true,
      accepted: false,
      storeProvided: false,
      multiStore: false,
      storeNotFound: false,
      storesNotFound: false,
      selectedStore: null,
      rejected: false
    };
  },
  watch: {
    store() {
      this.selectedStore = this.store;
    }
  },

  methods: {
    visible() {
      return !this.isMobile(window.innerWidth);
    },
    checkClaimStatus() {
      this.$store.dispatch("claims/checkClaimStatus");
      if (this.claim.is_routed) {
        clearInterval(this.checker);
        // this.determineNextStep();
        if (this.$route.query.claim_type) {
          if (this.$route.query.claim_type == "1") {
            this.$router.push({
              name: "report.damage",
              query: { routed: true, category: this.$route.query.category }
            });
          } else {
            this.$router.push({
              name: "report.theft",
              query: { routed: true, category: this.$route.query.category }
            });
          }
        }
      }
    }
  },

  mounted() {
    this.checker = setInterval(() => {
      this.checkClaimStatus();
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.checker);
  }
};
</script>

<style lang="scss">
@import "../../theme/variables";

.init {
  text-align: center;
  padding-top: 125px;
}

.init-logo {
  height: 37px;
}

.init-text {
  top: 20vh;
  text-align: center;
  width: 100%;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

.init-progress {
  position: relative;
  height: 5px;
  display: block;
  width: 100%;
  background-color: lighten($color-primary, 40%);
  border-radius: 5px;
  background-clip: padding-box;
  margin: 5rem 0 1rem 0;
  overflow: hidden;
  .init-progress-indeterminate {
    background-color: $color-primary;
    &:before {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
        infinite;
    }
    &:after {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
      animation-delay: 1.15s;
    }
  }
}

@include keyframes(indeterminate) {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@include keyframes(indeterminate-short) {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
</style>
